<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>업체관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li @click="pannelHidden"><a data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 1050px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label>{{ detailsFieldMap.companyNm }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									:placeholder="detailsFieldMap.companyNm"
									v-model="searchVM.companyNm"
									:maxlength="maxLength.companyNm"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 350px">
							<div class="form-group">
								<label>{{ detailsFieldMap.bzno }}</label>
								<input
									type="number"
									id="searchBzno"
									class="form-control"
									:placeholder="detailsFieldMap.bzno"
									v-model="searchVM.bzno"
									:maxlength="maxLength.bzno"
									@keyup="checkBznoLength()"
								/>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li @click="pannelHidden"><a data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								업체관리정보
							</legend>
							<div class="col-md-6">
								<!-- 업체ID, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.companyId"
									required="true"
									v-if="isEditMode"
									:disabled="!isCreateMode"
									field="detailsItem.companyId"
									data-vv-name="detailsItem.companyId"
									v-model="detailsItem.companyId"
									v-validate="validationRule.detailsItem.companyId"
								></jarvis-field>
								<!-- 사업자등록번호 -->
								<jarvis-field
									:label="detailsFieldMap.bzno"
									field="detailsItem.bzno"
									id="firstInputCreate"
									v-model="detailsItem.bzno"
									data-vv-name="detailsItem.bzno"
									v-validate="validationRule.detailsItem.bzno"
									:maxLength="maxLength.bzno"
								></jarvis-field>
								<!-- 휴대폰번호 -->
								<jarvis-field
									:label="detailsFieldMap.telNo"
									field="detailsItem.telNo"
									v-model="detailsItem.telNo"
									data-vv-name="detailsItem.telNo"
									v-validate="validationRule.detailsItem.telNo"
									:maxLength="maxLength.telNo"
								></jarvis-field>
							</div>
							<div class="row col-md-6">
								<!-- 업체명 -->
								<div class="col-lg-12">
									<!-- <div class="row itemWrapper col-lg-9" :class="errorCompanyNm" style="display: block"> -->
									<!-- <label class="col-lg-3 title" style="text-align: right; margin-left: 10px">
										업체명:
										<span class="required">*</span>
									</label> -->
									<!-- <div class="row col-lg-9" style="width: 400px; margin-right: -10px"> -->
									<!-- <input
												type="text"
												class="form-control"
												v-model="detailsItem.companyNm"
												@blur="duplicateCheck"
												data-vv-name="detailsItem.companyNm"
												v-validate="validationRule.detailsItem.companyNm"
												:maxLength="maxLength.companyNm"
											/> -->
									<jarvis-field
										:label="detailsFieldMap.companyNm"
										required="true"
										field="detailsItem.companyNm"
										v-model="detailsItem.companyNm"
										data-vv-name="detailsItem.companyNm"
										v-validate="validationRule.detailsItem.companyNm"
										:maxLength="maxLength.companyNm"
									></jarvis-field>
									<!-- <label class="msg"></label> -->
								</div>
								<div class="col-lg-12">
									<jarvis-field :label="detailsFieldMap.companyGrad" field="detailsItem.companyGrad" required="true">
										<select2
											v-model="detailsItem.companyGrad"
											v-validate="validationRule.detailsItem.companyGrad"
											data-vv-name="detailsItem.companyGrad"
											:options="detailsOptions.gradOption"
										>
											<option :value="null">선택</option>
											<!-- <option v-for="(row, index) in detailsOptions.gradOption" :key="index" :value="row.value">{{ row.text }}</option> -->
										</select2>
									</jarvis-field>
								</div>
								<div class="col-lg-12">
									<!-- 이메일 -->
									<jarvis-field
										:label="detailsFieldMap.emal"
										field="detailsItem.emal"
										v-model="detailsItem.emal"
										data-vv-name="detailsItem.emal"
										v-validate="validationRule.detailsItem.emal"
										:maxLength="maxLength.emal"
									></jarvis-field>
								</div>
							</div>
						</fieldset>
					</div>

					<div class="row panel-detail">
						<div class="col-md-6">
							<div class="form-group">
								<!-- 등록일. 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<!-- 등록자. 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									:disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<!-- 수정일. 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<!-- 수정자. 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									:disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>
						</div>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
// import validator from '../../lib/validator';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention;
const companyUrl = apiIndex.company;
//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		companyNm: 'max:100|required',
		companyGrad: 'required',
		bzno: 'numeric|min:10|max:10',
		telNo: 'phoneNoAndMobileNoPattern',
		emal: 'max:100|email',
	},
	edit: {
		//수정
		// regDate: 'required|momentDateTime',
	},
	create: {
		//생성
		// password: 'required|min:4|max:20',
	},
};
const maxLength = {
	companyNm: '100',
	bzno: '10',
	telNo: '11',
	emal: '100',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	data: () => ({
		pageParam: {
			mstCodeOptions: 1,
		},
		apiUrl: {
			excelApi: companyUrl.exlCompany,
			pageListApi: companyUrl.inqCompany,
			detailsApi: companyUrl.inqOneCompany,
			checkCompanyNmApi: companyUrl.inqCheckCompanyNm,
			insCompanyApi: companyUrl.insCompany,
			updCompanyApi: companyUrl.updCompany,
			delCompanyApi: companyUrl.delCompany,
		},
		//상세 select options
		detailsOptions: {
			gradOption: [],
		},
		searchVM: {
			companyNm: '',
			bzno: '',
		},
		//적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'companyId', title: '업체ID', width: '10%', align: 'center' },
			{ field: 'companyNm', title: '업체명', width: '30%' },
			{
				field: 'companyGrad',
				title: '업체 유형',
				dataFormat: 'optionMap',
				width: '10%',
				align: 'center',
			},
			{
				field: 'bzno',
				title: '사업자번호',
				dataFormat: 'hyphenBzno',
				width: '15%',
				align: 'center',
			},
			{
				field: 'telNo',
				title: '연락처',
				dataFormat: 'hyphenPhone',
				width: '15%',
				align: 'center',
			},
			{ field: 'emal', title: '이메일', width: '20%', align: 'center' },
		],
		detailsFieldMap: {
			companyId: '업체ID',
			companyNm: '업체명',
			companyGrad: '업체 유형',
			bzno: '사업자등록번호',
			emal: '이메일',
			telNo: '연락처',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {
			companyNm: null,
		},
		maxLength,
		isValidCompanyNm: true,
		debug: false,
	}),
	created() {
		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
		this.detailsOptions.gradOption = commonCodeList.filter(item => item.grpCd == 'company_grad');

		this.gridColumns.filter(item => item.field == 'companyGrad')[0].mapData = this.detailsOptions.gradOption;
		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			// return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
			return this.detailsItem.companyNm != null && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePassword) {
					rule = $.extend(true /*deep*/, { password: 'required|min:4|max:20' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					// this.$scrollTo('#detailsForm', 300);
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		checkBznoLength() {
			let bzno = document.getElementById('searchBzno');
			if (bzno.value.length > this.maxLength.bzno) {
				bzno.value = bzno.value.slice(0, this.maxLength.bzno);
			}
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '업체관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(this.apiUrl.detailsApi, { companyId: selectedRowItem.companyId })
					.then(
						function (response) {
							// this.errorCompanyNm = '';

							// this.curCompanyNm = response.data.companyNm;
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			// this.curCompanyNm = '';
			// this.errorCompanyNm = '';
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				companyId: '',
				companyNm: '',
				companyGrad: '',
				bzno: '',
				emal: '',
				telNo: '',
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//업체명 중복확인
		async duplicateCheck() {
			// this.errorCompanyNm = '';
			const cursor = this;
			if (this.detailsItemOriginal.companyNm == this.detailsItem.companyNm) {
				this.isValidCompanyNm = true;
				return;
			}
			if (this.detailsItem.companyNm.length != 0) {
				// if (this.curCompanyNm == '' || this.curCompanyNm != this.detailsItem.companyNm) {
				await this.$axios
					.post(this.apiUrl.checkCompanyNmApi, { companyNm: this.detailsItem.companyNm })
					.then(
						function (response) {
							console.log(response.data);
							if (response.data != '') {
								// this.errorCompanyNm = 'dupCompanyNm';
								cursor.isValidCompanyNm = false;
							} else {
								cursor.isValidCompanyNm = true;
							}
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
				// }
			}
			// else {
			// 	this.errorCompanyNm = 'reqCompanyNm';
			// }
		},
		//신규저장
		async createData() {
			await this.duplicateCheck();
			// if (this.errorCompanyNm.length != 0) return;
			this.$validator.validateAll().then(success => {
				if (success == false) {
					// 추가 버튼 클릭하고, 업체명 컬럼 건너 뛰고 저장 누른경우
					// this.errorCompanyNm = 'reqCompanyNm';
					return;
				}
				if (this.isValidCompanyNm == false) {
					alert('업체명이 중복되었습니다.');
					this.detailsItem.companyNm = '';
					return;
				}
				this.$axios
					.post(this.apiUrl.insCompanyApi, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			});
		},
		//수정저장
		async updateData() {
			await this.duplicateCheck();
			// if (this.errorCompanyNm.length) return;
			this.$validator.validateAll().then(success => {
				if (success == false) {
					return;
				}
				if (this.isValidCompanyNm == false) {
					alert('업체명이 중복되었습니다.');
					this.detailsItem.companyNm = '';
					return;
				}
				this.detailsItem.oriCompanyNm = this.detailsItemOriginal.companyNm;
				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					this.$axios
						.post(this.apiUrl.updCompanyApi, this.detailsItem)
						.then(
							function () {
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(this.apiUrl.delCompanyApi, { companyId: this.detailsItem.companyId })
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
.required {
	color: red;
}
#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	margin-top: 5px;
}
#rootVM > .panel .panel-body .form-group input {
	max-width: 200px;
	margin-left: 15px;
}

.itemWrapper {
	display: flex;
	margin-bottom: 20px;
}
.itemWrapper > label {
	margin-top: 7px;
}

.itemWrapper > div > label {
	margin-top: 7px;
	margin-bottom: 7px;
	display: block;
	color: red;
	position: relative;
	padding-left: 26px;
	font-size: 13px;
	display: none;
}

/* .itemWrapper .msg:before {
	content: '\ed63';
	font-family: 'icomoon';
	font-size: 16px;
	position: absolute;
	top: 50px;
	left: 0;
} */

/* .itemWrapper.dupCompanyNm > label,
.itemWrapper.reqCompanyNm > label {
	color: red;
}
.itemWrapper.dupCompanyNm .msg,
.itemWrapper.reqCompanyNm .msg {
	display: block;
}
.itemWrapper.dupCompanyNm input,
.itemWrapper.reqCompanyNm input {
	border: 1px solid red;
} */
/* .itemWrapper.dupCompanyNm .msg:after {
	content: '이미 등록된 업체명입니다.';
} */
/* .itemWrapper.reqCompanyNm .msg:after {
	content: '업체명은 필수 정보입니다.';
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

@media (max-width: 580px) {
	.itemWrapper {
		flex-direction: column;
	}
}
</style>
